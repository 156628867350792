import { disclosureStates } from "../components/App/Partner/Applications/stateValidations/allStateValidations";
import { currency } from "../components/App/shared/format";

export class OfferSummaryModel {
  constructor(summary) {
    Object.assign(this, summary)
  }

  get status() {
    if (this.renewalStatus) {
      return 'ELIGIBLE FOR RENEWAL';
    }
    if (this.closedStatus) {
      return 'CLOSED'
    }
    if (this.declinedStatus) {
      return 'DECLINED';
    }
    if (this.attentionStatus) {
      return 'NEEDS ATTENTION'
    }
    if (this.approvedStatus) {
      return 'APPROVED'
    }
    return "SUBMITTED IN REVIEW"
  }

  get effectiveApprovedAmount() {
    if (this.declinedStatus) {
      return null;
    }
    if (this.closedStatus) {
      return currency(this.startingAmount);
    }
    if (this.approvedStatus) {
      return currency(this.approvedAmount);
    }
    return currency(this.requestedAmount);
  }
}

export default class OfferModel {
  constructor(offer) {
    const { summary, ...rest } = offer
    this.summary = new OfferSummaryModel(summary)
    Object.assign(this, rest)
  }

  get showDisclosure() {
    return disclosureStates.includes(this.merchant.state_province)
  }
}
